import React, { useState, useContext, useEffect } from 'react';
import { LanguageContext } from '../language.context';
import toastError from "./Toast/toastError";
import emailjs from 'emailjs-com';
import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import FormSubmited from '../pages/FormSubmited';

const firebaseConfig = {
    apiKey: "AIzaSyDlwS7-PHThB2Rmp2pEKaePWfDPaSbQ1u8",
    authDomain: "dionamiteform.firebaseapp.com",
    projectId: "dionamiteform",
    storageBucket: "dionamiteform.appspot.com",
    messagingSenderId: "832284018922",
    appId: "1:832284018922:web:212ffd9ed535a3a29daf93",
    measurementId: "G-RNCV6KGMHC"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function Form(props) {
    const { strings } = useContext(LanguageContext);
    const [Submitted, setSubmitted] = useState(false)
    const [posFirstWord, setPosFirstWord] = useState(0);
    const [posSecondWord, setPosSecondWord] = useState(0);
    const [posThirdWord, setPosThirdWord] = useState(0);
    const [Submitting, setSubmitting] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            /*
             This function is used to make the text scrolling animation it verifies wich kind of device(innerWidth) we are using 
            after that verifies if  (percentage of scroll > desired break point for the animation) 
            if so the animation is aplied, if not the text returns to the begining positions 
            */
            if (window.innerWidth >= 1024) { // Desktop
                if ((window.scrollY * 100) / document.documentElement.scrollHeight > 79) {
                    setPosFirstWord(4.5)
                    setPosSecondWord(4.5)
                    setPosThirdWord(4.5)
                } else {
                    setPosFirstWord(0)
                    setPosSecondWord(1.5)
                    setPosThirdWord(3)
                }
            } else if (window.innerWidth > 768) { // Large tablet
                if ((window.scrollY * 100) / document.documentElement.scrollHeight > 79) {
                    setPosFirstWord(4.5)
                    setPosSecondWord(4.5)
                    setPosThirdWord(4.5)
                } else {
                    setPosFirstWord(0)
                    setPosSecondWord(1.5)
                    setPosThirdWord(3)
                }
            }
            else if (window.innerWidth >= 640) { //  Tablet
                if ((window.scrollY * 100) / document.documentElement.scrollHeight > 76) {
                    setPosFirstWord(4.5)
                    setPosSecondWord(4.5)
                    setPosThirdWord(4.5)
                } else {
                    setPosFirstWord(0)
                    setPosSecondWord(1.5)
                    setPosThirdWord(3)
                }
            } else { // Smartphone
                if ((window.scrollY * 100) / document.documentElement.scrollHeight > 76) {
                    setPosFirstWord(4.5)
                    setPosSecondWord(4.5)
                    setPosThirdWord(4.5)
                } else {
                    setPosFirstWord(0)
                    setPosSecondWord(1.5)
                    setPosThirdWord(3)
                }
            }
        };

        // Attach the scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [formData, setFormData] = useState({
        fullname: '',
        email: '',
        company: '',
        projectType: '',
        message: '',
    });

    /* const handleSubmit = async () => {
        if (formData.fullname && formData.email && formData.company && formData.projectType && formData.message) {
            if (formData.fullname.match(/^[A-Za-zÀ-ÿçÇ\s]*$/)) {
                if (formData.email.match(/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/)) {
                    startLoading()
                    try {
                        fetch(`${process.env.REACT_APP_API_URL}/form`, {
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                            },
                            method: 'POST',
                            body: JSON.stringify({
                                formData: formData,
                            })
                        })
                        setInterval(() => {
                            stopLoading()
                            navigate("/contact-success")
                        }, 500)
                    } catch (error) {
                        console.error('Error submitting data:', error);
                    }
                } else toastError(strings.home.form.errorMessages.email)
            } else toastError(strings.home.form.errorMessages.name)
        } else toastError(strings.home.form.errorMessages.allFields)
    }; */
    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true)
        if (!formData.fullname || !formData.email || !formData.company /* || !formData.projectType */ || !formData.message) {
            toastError(strings.home.form.errorMessages.allFields);
            return;
        }

        if (!formData.fullname.match(/^[A-Za-zÀ-ÿçÇ\s]*$/)) {
            toastError(strings.home.form.errorMessages.name);
            return;
        }

        if (!formData.email.match(/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/)) {
            toastError(strings.home.form.errorMessages.email);
            return;
        }

        try {

            // Sending form data to email using EmailJS
            emailjs.send('service_9anr04k', 'template_qpbraiu', formData, 'NHpSHmXVdTR52k5HN')
                // emailjs.send('service_sm0bjg7', 'template_3qpis8z', formData, 'twRuWq-X1NkN-PrOz') testing for ruben
                .then((response) => {
                    console.log('Email sent successfully');
                    setSubmitted(true)
                    setSubmitting(false)
                }, (error) => {
                    console.error('Failed to send email:', error);
                    toastError(strings.home.form.errorMessages.catchError);
                });

            setFormData({
                fullname: '',
                email: '',
                company: '',
                /*                 projectType: '', */
                message: '',
            });
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };

    return (
        <>
            <section className='mt-16' id='work-with-us'>
                <div className='relative h-[18rem] lg:h-[22rem]'>
                    <div className='font-TitilliumWeb-Bold select-none text-[4rem] sm:text-[6rem] lg:text-[8rem] xl:text-[10.2rem] font-bold text-orange opacity-80 uppercase textTransparent w-full text-center absolute transition-all duration-1000 leading-[1.2] ' style={{ top: `${posFirstWord}rem` }}>
                        WORK WITH US
                    </div>
                    <div className='font-TitilliumWeb-Bold select-none text-[4rem] sm:text-[6rem] lg:text-[8rem] xl:text-[10.2rem] font-bold text-orange opacity-20 uppercase textTransparent text-center w-full absolute top-6 textTransparent transition-all duration-1000 leading-[1.2]' style={{ top: `${posSecondWord}rem` }}>
                        WORK WITH US
                    </div>
                    <div className='font-TitilliumWeb-Bold select-none text-[4rem] sm:text-[6rem] lg:text-[8rem] xl:text-[10.2rem] font-bold text-orange opacity-10 uppercase text-center w-full absolute top-12 textTransparent transition-all duration-1000 leading-[1.2]' style={{ top: `${posThirdWord}rem` }}>
                        WORK WITH US
                    </div>
                    <div className='font-TitilliumWeb-Bold select-none text-[4rem] sm:text-[6rem] lg:text-[8rem] xl:text-[10.2rem] font-bold text-orange opacity-5 uppercase text-center w-full absolute top-[4.5rem] textTransparent leading-[1.2]'>
                        WORK WITH US
                    </div>


                </div>

                {/* <div className='flex justify-center w-full'>
                    <a href="hello@dionamite.com" className="w-3/4 p-3 px-10 mt-6 font-bold tracking-wider text-white uppercase transition-opacity sm:w-fit bg-gradient-to-r darkOrangeGradient text-buttonText hover:opacity-75" style={{ clipPath: 'polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%)' }}>
                        {strings.home.closing}
                    </a>
                </div> */}


                <div className='mx-6 lg:mx-36' id='form'>
                    <div className='rounded-xl p-[1px] borderGradientForm'>
                        <div className='rounded-xl cardGradientForm'>
                            <div className='relative px-4 py-8 md:px-24 md:py-16 rounded-xl bottomGradientForm'>
                                {Submitted && <FormSubmited setSubmitted={setSubmitted} />}
                                <div className='grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-x-8'>
                                    <div className='relative mb-4 md:mb-0 contact-meeting-container'>
                                        <h2 className='font-bold text-center leading-[1.15em] text-white sm:text-heading1 text-heading2 sm:text-left mb-4'>
                                            {strings.home.form.heading}<span className='text-orange'>.</span>
                                        </h2>
                                        <div className='flex justify-start pt-4 sm:pt-0 contact-meeting'>
                                        </div>
                                    </div>
                                    <div className='w-[85%] mx-auto'>
                                        <p className='text-regularP text-darkGray'>
                                            {strings.home.form.subheading} <span className='text-white'>{strings.home.form.subheading1}</span>
                                            <span className='text-orange'> {strings.home.form.subheading2}</span>{strings.home.form.subheading3}
                                        </p>
                                        <div>
                                            <div className='flex flex-col mt-7'>
                                                <label className='font-bold tracking-widest text-white uppercase text-heading6'>
                                                    {strings.home.form.name}
                                                </label>
                                                <input className='px-6 py-4 text-white rounded-md bg-[#393939] mt-1 border border-[#575757]' placeholder='Eg.: Richard Dawson' value={formData.fullname} onChange={(e) => setFormData({ ...formData, fullname: e.target.value })}
                                                />
                                            </div>
                                            <div className='flex flex-col mt-5'>
                                                <label className='font-bold tracking-widest text-white uppercase text-heading6 '>
                                                    {strings.home.form.email}
                                                </label>
                                                <input className='px-6 py-4 rounded-md text-white bg-[#393939] mt-1 border border-[#575757]' placeholder='Eg.: richard@dawson.com' onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                                    value={formData.email} />
                                            </div>
                                            <div className='flex flex-col mt-5'>
                                                <label className='font-bold tracking-widest text-white uppercase text-heading6 '>
                                                    {strings.home.form.company}
                                                </label>
                                                <input className='px-6 py-4 rounded-md text-white bg-[#393939] mt-1 border border-[#575757]' placeholder='Eg.: Dawson Marketing Agency' onChange={(e) => setFormData({ ...formData, company: e.target.value })}
                                                    value={formData.company} />
                                            </div>
                                            <div className='flex flex-col mt-5'>
                                                <label className='font-bold tracking-widest text-white uppercase text-heading6 '>
                                                    {strings.home.form.project}
                                                </label>
                                                <textarea rows={4} className='text-white resize-none px-6 py-4 rounded-md bg-[#393939] mt-1 border border-[#575757]' placeholder={strings.home.form.projectPlaceholder} onChange={(e) => setFormData({ ...formData, message: e.target.value })} value={formData.message} />
                                            </div>
                                            <div className='flex justify-center sm:justify-end'>
                                                <button onClick={handleSubmit} className="w-3/4 p-3 px-10 mt-6 font-bold tracking-wider text-white uppercase transition-opacity sm:w-fit bg-gradient-to-r darkOrangeGradient text-buttonText hover:opacity-75" style={{ clipPath: 'polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%)' }}>
                                                    {Submitting ? <div
                                                        role="status"
                                                        className="flex justify-center items-center gap-x-2"
                                                    >
                                                        <svg
                                                            aria-hidden="true"
                                                            className="w-4 h-4 animate-spin fill-[#1b1b1b]"
                                                            viewBox="0 0 100 101"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M100 50.5908C100 78.2051 77.6142 100.591 
                                             50 100.591C22.3858 100.591 0 78.2051 0 
                                             50.5908C0 22.9766 22.3858 0.59082 50 
                                             0.59082C77.6142 0.59082 100 22.9766 
                                             100 50.5908ZM9.08144 50.5908C9.08144 
                                             73.1895 27.4013 91.5094 50 91.5094C72.5987 
                                             91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 
                                             27.9921 72.5987 9.67226 50 9.67226C27.4013 
                                             9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M93.9676 39.0409C96.393 38.4038 
                                             97.8624 35.9116 97.0079 33.5539C95.2932 
                                             28.8227 92.871 24.3692 89.8167 20.348C85.8452 
                                             15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 
                                             4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 
                                             0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 
                                             1.69328 37.813 4.19778 38.4501 6.62326C39.0873 
                                             9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 
                                             9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 
                                             10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 
                                             17.9648 79.3347 21.5619 82.5849 25.841C84.9175 
                                             28.9121 86.7997 32.2913 88.1811 35.8758C89.083 
                                             38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                fill="currentFill"
                                                            />
                                                        </svg>
                                                        <span>{strings.home.form.submitting}</span>
                                                    </div> : <span>{strings.home.form.submit}</span>}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <img className='absolute opacity-25 select-none sm:opacity-100 -right-10 -bottom-12 w-36 lg:w-72 lg:-bottom-32 lg:-right-32' src='images/footer/dinamites.png' alt='Dinamites' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Form;
